import React from 'react'
import { Select, Tooltip } from 'antd';
import { appConfig } from '../config';

const {Option} = Select;
const options = [15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0, -1, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14, -15]

type VolumeSelectProps = {
    value: number | undefined;
    onChange: any;
    loading: boolean;
    disabled: boolean;
    tooltip: string;
    max?: number | undefined;
    min?: number | undefined;
}

export const VolumeSelect: React.FC<VolumeSelectProps> = ({
                                                              value,
                                                              onChange,
                                                              loading,
                                                              disabled,
                                                              tooltip,
                                                              max = 15,
                                                              min = -15
                                                          }) => {
    return (
        <Tooltip title={tooltip} placement="right">
            <Select size={appConfig.ui.buttonSize} value={value} onChange={onChange} loading={loading}
                    disabled={disabled}>
                {value === undefined && <Option value="">?</Option>}
                {options
                    .filter(o => o >= min && o <= max)
                    .map(o => <Option key={o} value={o}>{o}</Option>)}
            </Select>
        </Tooltip>
    );
};

export default VolumeSelect
