import React, { useCallback, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Col, Row } from 'antd';

import { BbbContext } from '../logic/bbb';
import { SpectrumRow } from '../components/SpectrumRow';

const AudioSpectrum = observer((props) => {
    const bbb = useContext(BbbContext);
    const [isMonitoring, setIsMonitoring] = useState(false);

    useEffect(() => {
        bbb.fetchDashboard();
        // setIsMonitoring(!isMonitoring);
    }, [])

    const toggleMonitoring = useCallback(() => {
        setIsMonitoring(!isMonitoring);
    }, [setIsMonitoring, isMonitoring])

    const mainUrl = "https://stream.radiomaitreya.org:8443/radio_maitreya";

    return (<>
        <Row>
            <Col span={1}>
                <Button onClick={toggleMonitoring}>{isMonitoring ? "Parar" : "Reanudar"} el monitoreo</Button>
            </Col>
        </Row>
        <SpectrumRow audioUrl={mainUrl} name="PRINCIPAL" isMonitoring={isMonitoring}/>
        {bbb.conferences?.map(c => {
            const {streamOut} = c;
            const streamPreviewUrl = streamOut?.previewUrl;
            return (<SpectrumRow key={c.id} audioUrl={streamPreviewUrl} name={c.name} isMonitoring={isMonitoring}/>);
        })}

    </>)
});

export default AudioSpectrum
