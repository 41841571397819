import React, { useCallback, useContext, useRef, useState } from 'react'
import { RadioRelayChannelStatus, SecurityTrackMode, StreamOutMode } from '../gen/BBBApiService';
import { RelayContext } from '../logic/relay';
import { Button, Col, Row, Switch, Tag, Tooltip } from 'antd';
import { ApiOutlined, CaretRightFilled, LoadingOutlined, PauseOutlined } from '@ant-design/icons';
import { appConfig } from '../config';
import ReactPlayer from 'react-player';

const RadioRelayRow = (props: { data: RadioRelayChannelStatus }) => {
    const ctx = useContext(RelayContext)
    const c = props.data;

    const [isPreviewPlay, setIsPreviewPlay] = useState(false)
    const playerRef = useRef(null);
    const streamPreviewUrl = isPreviewPlay ? c.monitorUrl : ""

    const handlePreviewPlay = useCallback(() => {
        setIsPreviewPlay(true)
        const playerCurrent: any = playerRef.current;
        if (!playerCurrent) return;
        const player = playerCurrent.getInternalPlayer();
        if (!player) return;
        player.pause();
        player.currentTime = 0;
        player.src = streamPreviewUrl;
        player.play().catch((err) => {
            setIsPreviewPlay(false)
            console.warn(err);
        });
    }, [streamPreviewUrl])

    const handlePreviewStop = useCallback(() => {
        setIsPreviewPlay(false)
        const playerCurrent: any = playerRef.current;
        if (!playerCurrent) return;

        const player = playerCurrent.getInternalPlayer();
        if (!player) return;

        player.currentTime = 0;
        player.pause();
        player.src = "";
        player.load();
    }, [playerRef]);

    const setStreamInMode = useCallback((isAuto) => {
        const mode = isAuto ? StreamOutMode.Auto : StreamOutMode.Manual;
        if (isAuto && !c.streamOutIsStreaming) {
            if (window.confirm(`Esta seguro que quere INICIAR la transmisión a "${c.channelName}"`)) {
                ctx.setStreamOutMode(c.channelId!, mode);
            }
        } else {
            ctx.setStreamOutMode(c.channelId!, mode);
        }
    }, [ctx, c]);

    const stopStreamOut = useCallback(() => {
        if (window.confirm(`Esta seguro que quere CORTAR la transmisión a "${c.channelName}"`)) {
            ctx.stopStreamOut(c.channelId!)
        }
    }, [ctx, c]);

    const startStreamOut = useCallback(() => {
        if (window.confirm(`Esta seguro que quere INICIAR la transmisión a "${c.channelName}"`)) {
            ctx.startStreamOut(c.channelId!)
        }
    }, [ctx, c]);


    const streamOutTooltip = `Master: ${c.volMaster} Security: ${c.volSecurity}`;
    return (<Row align="middle">
        <Col span={2}><strong>{c.channelName}</strong></Col>
        <Col span={3}>
            <Tooltip title={c.masterInStatus} placement="left">
                {c.masterInConnected
                    ? <Tag color="green" icon={<LoadingOutlined/>}>Recibiendo audio</Tag>
                    : <Tag color="red" icon={<ApiOutlined/>}>Desconectado</Tag>}
            </Tooltip>
            &nbsp;
        </Col>

        <Col span={1}>
            <Tooltip title="Modo AUTO: automaticamente transmitir" placement="left">
                <Switch size={appConfig.ui.switchSize} checkedChildren="AUTO" unCheckedChildren="MAN" disabled={false}
                        onChange={setStreamInMode} checked={c.streamOutMode === StreamOutMode.Auto} loading={false}/>
            </Tooltip>
            &nbsp;
        </Col>

        <Col span={1}>
            {c.streamOutIsStreaming
                ? <Tooltip title="Parar transmisión" placement="left">
                    <Button size={appConfig.ui.buttonSize} type="primary" danger onClick={stopStreamOut}
                            disabled={false}>Stop</Button>
                </Tooltip>
                : <Tooltip title="Iniciar transmisión" placement="left">
                    <Button size={appConfig.ui.buttonSize} type="primary" onClick={startStreamOut}
                            disabled={false}>Start</Button>
                </Tooltip>
            }
        </Col>

        <Col span={2}>
            <Tooltip title={"Pista de seguridad/fallback"} placement="left">
                {c.securityTrack === SecurityTrackMode.Normal ? "Oooommm" : "Emergencia"}
            </Tooltip>
        </Col>

        <Col span={2}>
            <Tooltip title={streamOutTooltip}>
                {c.streamOutIsStreaming
                    ? <Tag color="green" icon={<LoadingOutlined/>}>Transmitiendo</Tag>
                    : <Tag color="red" icon={<ApiOutlined/>}>Desconectado</Tag>}
            </Tooltip>
        </Col>
        <Col span={1}>
            <Tooltip title="Escuchar la transmisión" placement="right">
                {isPreviewPlay
                    ? <Button size={appConfig.ui.buttonSize} shape="circle" type="primary" icon={<PauseOutlined/>}
                              color="#fa8c16"
                              onClick={handlePreviewStop}/>
                    : <Button size={appConfig.ui.buttonSize} shape="circle" icon={<CaretRightFilled/>}
                              onClick={handlePreviewPlay}/>}
            </Tooltip>
            <ReactPlayer url={streamPreviewUrl} config={{file: {}}} controls={false} playing={isPreviewPlay}
                         width={0} volume={1} height={0} ref={playerRef}
            />
        </Col>
    </Row>)
}

export default RadioRelayRow