import { DashConference, FsConfMember } from '../gen/BBBApiService';
import { Button, Col, Row, Typography } from 'antd';
import { SoundOutlined } from '@ant-design/icons';
import React, { useCallback, useContext } from 'react';
import { BbbContext } from '../logic/bbb';
import VolumeSelect from './VolumeSelect';
import { appConfig } from '../config';


export function ConferenceMemberRow(
    {
        member: {
            id,
            isTalking,
            isMuted,
            name,
            volumeIn,
            volumeOut,
            energy,
            isStreamInPlaying,
            uuid
        }, conference
    }:
        { member: FsConfMember, conference: DashConference }) {
    const bbb = useContext(BbbContext)

    const mute = useCallback(() => {
        bbb.muteMember(conference.id!, id!)
    }, [bbb, id, conference])

    const setMemberVolumeIn = useCallback((volume) => {
        bbb.setMemberVolume(conference.id!, id!, volume)
    }, [bbb, id, conference])

    const setMemberVolumeOut = useCallback((volume) => {
        bbb.setMemberVolumeOut(conference.id!, id!, volume)
    }, [bbb, id, conference])

    const startStreamIn = useCallback(() => {
        bbb.streamInTranslatorPlay(true, conference.id!, [id!])
    }, [bbb, id, conference])

    const stopStreamIn = useCallback(() => {
        bbb.streamInTranslatorPlay(false, conference.id!, [id!])
    }, [bbb, id, conference])

    const fetchMediaStats = useCallback(() => {
        bbb.fetchMemberStatus(conference.id!, uuid!, name!)
    }, [bbb, conference, uuid])

    const isLoading = bbb.isMemberLoading(id!)

    return (
        <Row>
            <Col span={1}>{id}</Col>
            <Col span={3}>{name}</Col>
            <Col span={1}/>
            <Col span={3}>
                <VolumeSelect value={volumeOut} onChange={setMemberVolumeOut} loading={false} disabled={isLoading}
                              tooltip="Volumen auricular"/>
            </Col>
            <Col span={1}>{isTalking && <SoundOutlined/>}</Col>
            <Col span={3}>
                {isStreamInPlaying
                    ? <Button size={appConfig.ui.buttonSize} danger onClick={stopStreamIn}
                              disabled={isLoading}>Stop</Button>
                    :
                    <Button size={appConfig.ui.buttonSize} onClick={startStreamIn} disabled={isLoading}>Start</Button>}
            </Col>
            <Col span={1}>
                <VolumeSelect value={volumeIn} onChange={setMemberVolumeIn} loading={false} disabled={isLoading}
                              tooltip="Volumen micrófono"/>
            </Col>
            <Col span={3}>
                {isMuted
                    ? <Button size={appConfig.ui.buttonSize} disabled data-testid="member-mute-button">Muted</Button>
                    : <Button size={appConfig.ui.buttonSize} danger onClick={mute} disabled={isLoading}
                              data-testid="member-mute-button">Mute</Button>
                }
                {" "}
                {energy}
            </Col>
            <Col span={6}>
                {/*<Typography.Paragraph copyable>{uuid}</Typography.Paragraph>*/}
            </Col>
            <Col span={1}>
                <Button size={appConfig.ui.buttonSize} onClick={fetchMediaStats}>Media stats</Button>
            </Col>
        </Row>
    );
}
