import { Tag, Tooltip } from 'antd';
import React from 'react';
import { FsServer, FsServerStatus, FsServerType } from '../gen/BBBApiService';
import { WarningOutlined } from '@ant-design/icons';


const ServerBadge = ({server}: { server: FsServerStatus | FsServer }) => {
    if (server instanceof FsServer) {
        return <Tag>{server.name}</Tag>
    }

    const type = server.serverType === FsServerType.Command ? "Command client" : "Listener client"
    const title = `${type}: ${server.statusMessage}`
    let color = server.active ? "#87d068" : "#f50";

    if (server.degraded) {
        color = "#fa8c16"
    }

    const icon = !server.active ? <WarningOutlined /> : null;

    return (<Tooltip key={server.id} title={title}>
        <Tag color={color}
             icon={icon}
             onClick={() => {
                 console.log(server.id)
             }}
        >{server.name}</Tag>
    </Tooltip>);
}

export default ServerBadge
