import Axios from 'axios';
import { appConfig } from '../config';
import { AuthService } from './authService';

const HttpClientFactory = (authModule: AuthService) => {
    const apiClient = Axios.create({
        baseURL: appConfig.baseUrl,
        // Wait for debugger indefinitely
        // timeout: appConfig.isDev ? 0 : 15000,
        timeout: 10000,
    });

    apiClient.interceptors.request.use(async function (config) {
        const user = await authModule.getUser()

        if (!user) throw new Error("You must sign in to complete this request.");

        config.headers["Authorization"] = `Bearer ${user.id_token}`;
        return config;
    });

    apiClient.interceptors.response.use(function (response) {
            return response;
        },
        async function (error) {
            try {
                const originalRequest = error.config;
                const status = error.response?.status;

                if ((status === 401 || status === 403) && !originalRequest._retry) {
                    await authModule.login()
                    // originalRequest._retry = true;
                    // await authModule.renewToken()
                    // console.log(user)
                    // if (!user) throw new Error("Token renewal failed");
                    //
                    // apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + user.id_token;
                    // return apiClient(originalRequest)
                }
            } catch (err) {
                // TODO: sentry
                console.error(err)
            } finally {
                return Promise.reject(error);
            }
        });

    return apiClient;
};

export default HttpClientFactory;
