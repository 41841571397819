import React, { useCallback, useEffect, useRef, useState } from 'react';
import AudioMotionAnalyzer from 'audiomotion-analyzer';
import { Button, Col, Row } from 'antd';
import ReactPlayer from 'react-player';

export function SpectrumRow({audioUrl, name, isMonitoring}) {
    const playerRef = useRef(null);
    const analyzer = useRef(null);
    const [isMuted, setIsMuted] = useState(true);
    const [audioMotion, setAudioMotion] = useState()

    useEffect(() => {
        if (!isMonitoring) {
            if (audioMotion) {
                audioMotion.toggleAnalyzer(false)
            }
            return
        } else {
            if (audioMotion) {
                audioMotion.toggleAnalyzer(true)
                return
            }

        };
        if (!playerRef.current) return;
        if (!analyzer.current) return;

        // @ts-ignore
        const player = playerRef.current.getInternalPlayer();
        if (!player) {
            console.log("Cant access player", player)
            return;
        }

        // @ts-ignore
        const ama = new AudioMotionAnalyzer(analyzer.current, {
            source: player, height: 180, width: 800,
            mode: 3,
            barSpace: .6,
            showLeds: true,
            minDecibels: -100,
            showPeaks: true,
            showScaleY: true,
            volume: 0,
        });
        setAudioMotion(ama)

        player.load();
        player.play();
        // player.volume = 1;
        player.crossOrigin = "anonymous";
    }, [playerRef, isMonitoring]);

    const toggleVolume = useCallback(() => {
        audioMotion.volume = isMuted ? 1 : 0;
        setIsMuted(!isMuted)
    }, [isMuted, audioMotion])

    return (
        <Row>
            <Col span={3}>
                {name}
                <Button onClick={toggleVolume}>{isMuted ? "Unmute" : "Mute"}</Button>
                <ReactPlayer url={audioUrl} config={{file: {}}}
                             controls={true} playing={false}
                             width={160} volume={1} height={50} ref={playerRef}
                />
            </Col>
            <Col span={8}>
                <div ref={analyzer}></div>
            </Col>
        </Row>
    )
}
