import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { RelayContext } from '../logic/relay';
import { Col, Row, Spin } from 'antd';
import RadioRelayRow from './RadioRelayRow';

const RadioRelayDashboard = observer(() => {
    const ctx = useContext(RelayContext);
    const isAutoRefresh = true

    useEffect(() => {
        ctx.fetchDashboard()
    }, [])

    useEffect(() => {
        let fetchInterval;
        if (isAutoRefresh) {
            fetchInterval = setInterval(() => ctx.fetchDashboard(), 2 * 1000);
        } else {
            clearInterval(fetchInterval);
        }
        return () => {
            clearInterval(fetchInterval);
        };
    }, [isAutoRefresh])

    const dash = ctx.dashboard;
    const channels = dash?.channels;
    const haveData = (channels != null && channels.length > 0);

    return (
        <Row style={{padding: 3, marginBottom: 2, backgroundColor: "#f0f5ff", border: "1px solid #d9d9d9", borderRadius: 4}}>
            <Col span={2}>
                Canales
            </Col>
            <Col span={22}>
                {haveData
                    ? channels!.map(c => <RadioRelayRow key={c.channelId} data={c}/>)
                    : <strong color={"red"}>No hay canales configurados o Relay está caido</strong>}
            </Col>
        </Row>
    )
});


export default RadioRelayDashboard;
