import { Avatar, Badge, Button, Col, Collapse, Row, Switch, Tooltip, Typography } from 'antd';
import {
    CaretRightFilled, PauseOutlined, SoundOutlined, SoundTwoTone, ApartmentOutlined, UserOutlined, UsergroupAddOutlined
} from '@ant-design/icons';

import VolumeSelect from './VolumeSelect';
import { ConferenceMemberRow } from './ConferenceMemberRow';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { BbbContext } from '../logic/bbb';
import { DashConference, StreamInMode, StreamInTranslatorMode, StreamOutMode } from '../gen/BBBApiService';
import ReactPlayer from 'react-player';
import ServerBadge from './ServerBadge';
import EnergyLevelSelect from './EnergyLevelSelect';
import StreamInTranslatorModeSelect from './StreamInTranslatorModeSelect';
import { appConfig } from '../config';
import SpectrogramPopover from './SpectrogramPopover';

const {Title} = Typography;
const {Panel} = Collapse;

const ConferenceRow = observer(({c}: { c: DashConference }) => {
    const bbb = useContext(BbbContext)
    const [isPreviewPlay, setIsPreviewPlay] = useState(false)
    const playerRef = useRef(null);
    const streamPreviewUrl = isPreviewPlay ? c.streamOut?.previewUrl : ""

    const handlePreviewPlay = useCallback(() => {
        setIsPreviewPlay(true)
        const playerCurrent: any = playerRef.current;
        if (!playerCurrent) return;
        const player = playerCurrent.getInternalPlayer();
        if (!player) return;
        player.pause();
        player.currentTime = 0;
        player.src = streamPreviewUrl;
        player.play().catch((err) => {
            setIsPreviewPlay(false)
            console.warn(err);
        });
    }, [streamPreviewUrl])

    const handlePreviewStop = useCallback(() => {
        setIsPreviewPlay(false)
        const playerCurrent: any = playerRef.current;
        if (!playerCurrent) return;

        const player = playerCurrent.getInternalPlayer();
        if (!player) return;

        player.currentTime = 0;
        player.pause();
        player.src = "";
        player.load();
    }, [playerRef]);

    const setStreamInMode = useCallback((isAuto) => {
        const mode = isAuto ? StreamInMode.Auto : StreamInMode.Manual;
        bbb.streamInMode(c.id, mode);
    }, [bbb, c]);

    const startStreamIn = useCallback(() => {
        bbb.streamInStart(c.id)
    }, [bbb, c]);

    const stopStreamIn = useCallback(() => {
        bbb.streamInStop(c.id)
    }, [bbb, c]);

    const stopStreamInTranslators = useCallback(() => {
        bbb.streamInTranslatorsConferenceStop(c.id)
    }, [bbb, c]);

    const setStreamInVolume = useCallback((vol: number) => {
            bbb.setStreamInVolume(c.id!, vol)
        },
        [bbb, c]);

    const setStreamInTranslatorMode = useCallback((mode: StreamInTranslatorMode) => {
        bbb.streamInTranslatorMode(c.id!, mode)
    }, [bbb, c]);

    const setStreamInTranslatorVolume = useCallback((vol: number) => {
        bbb.setStreamInTranslatorVolume(c.id!, vol)
    }, [bbb, c]);

    const setEnergyLevel = useCallback((level: number) => {
            bbb.setEnergyLevel(c.id!, level)
        },
        [bbb, c]);

    const muteMembers = useCallback(() => {
        bbb.muteMembers(c.id!)
    }, [bbb, c]);

    const unMuteMembers = useCallback(() => {
        console.log("TODO: unmute members")
        // bbb.muteMembers(c.id)
    }, []);

    const stopStreamOut = useCallback(() => {
        bbb.streamOutStop(c.id)
    }, [bbb, c]);

    const startStreamOut = useCallback(() => {
        bbb.streamOutStart(c.id)
    }, [bbb, c]);

    const stopMixerStream = useCallback(() => {
        bbb.mixerStreamStop(c.id)
    }, [bbb, c]);

    const startMixerStream = useCallback(() => {
        bbb.mixerStreamStart(c.id)
    }, [bbb, c]);

    const setStreamOutMode = useCallback((isAuto) => {
        const mode = isAuto ? StreamOutMode.Auto : StreamOutMode.Manual;
        bbb.streamOutMode(c.id, mode);
    }, [bbb, c]);

    const startRecording = useCallback(() => {
        bbb.recordingStart(c.id!)
    }, [bbb, c]);

    const stopRecording = useCallback(() => {
        bbb.recordingStop(c.id!)
    }, [bbb, c]);

    const isStreamInLoading = bbb.isStreamInLoading(c.id!)
    const isStreamInAuto = c.streamIn?.mode === StreamInMode.Auto;

    const isStreamInTranslatorLoading = bbb.isStreamInTranslatorLoading(c.id!)
    const stopStreamInTranslatorsDisabled = isStreamInTranslatorLoading
        || (c.streamIn?.translatorMode === StreamInTranslatorMode.Manual
            && !c.members?.find?.(m => m.isStreamInPlaying))

    const isStreamOutLoading = bbb.isStreamOutLoading(c.id!)
    const isStreamOutAuto = c.streamOut?.mode === StreamOutMode.Auto;

    const isRecording = bbb.isRecordingLoading(c.id!)
    const isMixerStreamLoading = bbb.isMixerStreamLoading(c.id!)
    const isStreamInLow = !c.isStreamInOnHigh;

    const isTranslatorSpeaking = c.members?.findIndex(m => m.isTalking) !== -1;

    const masterTranslatorIconTooltip = `Vol: Maestro ${c.mixer?.streamInVol} Traductor ${c.mixer?.translatorVol}`;
    return <>
        <Row align="middle">
            <Col span={4}>
                <Title level={5}>
                    {c.usesExternalMixer && <ApartmentOutlined style={{color: "red"}} title={"Usa mixer externo"}/>}
                    &nbsp;
                    {c.name} {c.id}
                </Title>
            </Col>
            <Col span={4}>
                <Tooltip title="Modo AUTO: Bajar volumen automaticamente cuando los traductoreas apagan sus micrófonos">
                    <Switch size={appConfig.ui.switchSize} checkedChildren="AUTO" unCheckedChildren="MAN"
                            disabled={isStreamInLoading}
                            onChange={setStreamInMode} checked={isStreamInAuto} loading={isStreamInLoading}/>
                </Tooltip>
                {" "}
                {c.streamIn?.isStreaming
                    ? <Tooltip title="Parar Español (fondo)">
                        <Button size={appConfig.ui.buttonSize} type="primary" danger onClick={stopStreamIn}
                                disabled={isStreamInLoading} data-testid="conference-stream-in-button">Stop</Button>
                    </Tooltip>
                    : <Tooltip title="Iniciar Español (fondo)">
                        <Button size={appConfig.ui.buttonSize} type="primary" onClick={startStreamIn}
                                disabled={isStreamInLoading} data-testid="conference-stream-in-button">Start</Button>
                    </Tooltip>
                }
                {" "}
                <VolumeSelect value={c.streamIn?.vol} onChange={setStreamInVolume} loading={false}
                              disabled={isStreamInLoading} tooltip="Volumen en Español (fondo)"/>
            </Col>
            <Col span={1}>
                {isStreamInLow
                    ? <Tooltip title={masterTranslatorIconTooltip}>
                        <Avatar size="small" shape="square">M</Avatar>
                        <Avatar size="small" style={{backgroundColor: 'green'}} shape="square">T</Avatar>
                    </Tooltip>
                    : <Tooltip title={masterTranslatorIconTooltip}>
                        <Avatar size="small" style={{backgroundColor: 'indigo'}} shape="square">M</Avatar>
                        <Avatar size="small" shape="square">T</Avatar>
                    </Tooltip>}
                {/*TODO: Show peak*/}
                &nbsp;
                {isTranslatorSpeaking && <SoundOutlined title={"Traductor hablando"}/>}
            </Col>
            <Col span={4}>
                <Tooltip title="Parar Español (auriculares trad.) para todos los traductores en la sala">
                    <Button size={appConfig.ui.buttonSize} type="primary" danger onClick={stopStreamInTranslators}
                            disabled={stopStreamInTranslatorsDisabled}>Stop</Button>
                </Tooltip>
                <StreamInTranslatorModeSelect value={c.streamIn?.translatorMode} onChange={setStreamInTranslatorMode}
                                              loading={false} disabled={isStreamInTranslatorLoading}
                                              tooltip="Modo de Español para los auriculares de traductores"/>
                <VolumeSelect value={c.streamIn?.translatorVolumeConfig} onChange={setStreamInTranslatorVolume}
                              loading={false}
                              disabled={isStreamInTranslatorLoading}
                              min={10} max={10}
                              tooltip="Volumen en Español para los auriculares de traductor. Al cambiar este valor el traductor escuchará un pequeño corte."/>
            </Col>
            <Col span={3}>
                <Tooltip title="Mutear todos los traductores en la sala">
                    {c.allMembersAreMuted
                        ? <Button size={appConfig.ui.buttonSize} type="primary" onClick={unMuteMembers}
                                  disabled={true} data-testid="conference-members-mute-button">Muted</Button>
                        :
                        <Button size={appConfig.ui.buttonSize} danger type="primary" onClick={muteMembers}
                                data-testid="conference-members-mute-button">Mute</Button>
                    }
                </Tooltip>
                {" "}
                {/*// TODO: fix + add loading*/}
                <EnergyLevelSelect value={c.energyLevel} onChange={setEnergyLevel} loading={false} disabled={false}/>
            </Col>
            {!c.usesExternalMixer && <Col span={2}/>}
            <Col span={4}>
                <Tooltip title="Transmitir automaticamente cuando se crea esta sala">
                    <Switch checkedChildren="AUTO" unCheckedChildren="MAN" disabled={isStreamOutLoading}
                            onChange={setStreamOutMode} checked={isStreamOutAuto} loading={isStreamOutLoading}/>
                </Tooltip>
                {" "}
                {c.streamOut?.isStreaming
                    ?
                    <Tooltip title="Parar la traducción">
                        <Button size={appConfig.ui.buttonSize} type="primary" danger onClick={stopStreamOut}
                                loading={isStreamOutLoading}
                                disabled={isStreamOutLoading}>Stop</Button>
                    </Tooltip>
                    :
                    <Tooltip title="Iniciar la traducción">
                        <Button size={appConfig.ui.buttonSize} type="primary" onClick={startStreamOut}
                                loading={isStreamOutLoading}
                                disabled={isStreamOutLoading}>Start</Button>
                    </Tooltip>
                }
            </Col>
            {c.usesExternalMixer &&
                // <Col span={2}>
                //     {c.recording?.isRecording
                //         ?
                //         <Tooltip title="Parar la grabación">
                //             <Button size={appConfig.ui.buttonSize}type="primary" danger onClick={stopRecording} loading={isRecording}
                //                     disabled={isRecording}>Stop</Button>
                //         </Tooltip>
                //         :
                //         <Tooltip title="Iniciar la grabación">
                //             <Button size={appConfig.ui.buttonSize}type="primary" onClick={startRecording} loading={isRecording}
                //                     disabled={isRecording}>Start</Button>
                //         </Tooltip>
                //     }
                // </Col>
                <Col span={2}>
                    {c.mixer?.isStreaming
                        ?
                        <Tooltip title="Parar la transmisión">
                            <Button size={appConfig.ui.buttonSize} type="primary" danger onClick={stopMixerStream}
                                    disabled={isMixerStreamLoading}>Stop</Button>
                        </Tooltip>
                        :
                        <Tooltip title="Iniciar la transmisión">
                            <Button size={appConfig.ui.buttonSize} type="primary" onClick={startMixerStream}
                                    disabled={isMixerStreamLoading}>Start</Button>
                        </Tooltip>
                    }
                </Col>}
            <Col span={1}>
                <Tooltip title="Escuchar la transmisión" placement="right">
                    {isPreviewPlay
                        ? <Button size={appConfig.ui.buttonSize} shape="circle" type="primary" icon={<PauseOutlined/>}
                                  color="#fa8c16"
                                  onClick={handlePreviewStop}/>
                        : <Button size={appConfig.ui.buttonSize} shape="circle" icon={<CaretRightFilled/>}
                                  onClick={handlePreviewPlay}/>}
                </Tooltip>
                <SpectrogramPopover conferenceId={c.id}/>
                <ReactPlayer url={streamPreviewUrl} config={{file: {}}} controls={false} playing={isPreviewPlay}
                             width={0} volume={1} height={0} ref={playerRef}
                />
            </Col>
            <Col span={1}>
                <ServerBadge server={c.server!}/>
            </Col>
        </Row>
        <Collapse bordered={false}>
            <Panel key={1}
                   header={<div>
                       {c.members?.length} participantes:{" "}
                       {c.members?.map(m =>
                           <span key={m.id} style={{fontWeight: m.isTalking ? "bold" : "normal", paddingRight: "3px"}}>
                        {m.name}
                               {" "}
                               {m.isTalking && <SoundOutlined/>}
                               |
                    </span>)}
                   </div>}>
                {c.members?.map(m => <ConferenceMemberRow key={m.id} member={m} conference={c}/>)}
            </Panel>
        </Collapse>
    </>
        ;
});

export default ConferenceRow;
