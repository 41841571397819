import React from 'react'
import { Select, Tooltip } from 'antd';
import { StreamInTranslatorMode } from '../gen/BBBApiService';
import { appConfig } from '../config';

const {Option} = Select;

type Props = {
    value: number | undefined;
    onChange: any;
    loading: boolean;
    disabled: boolean;
    tooltip: string;
}

export const StreamInTranslatorModeSelect: React.FC<Props> =
    ({value, onChange, loading, disabled, tooltip}) => (
        <Tooltip title={tooltip}>
            <Select size={appConfig.ui.buttonSize} value={value} onChange={onChange} loading={loading} disabled={disabled}
                    dropdownMatchSelectWidth={false}>
                <Option title="Reproducir para todos los traductores siempre"
                        value={StreamInTranslatorMode.Always}>Siempre</Option>
                <Option title="Reproducir solo cuando el micrófono de traductor está activo"
                        value={StreamInTranslatorMode.Auto}>Auto</Option>
                <Option title="Reproducir manualmente" value={StreamInTranslatorMode.Manual}>Manual</Option>
            </Select>
        </Tooltip>
    );

export default StreamInTranslatorModeSelect
