import React, { useContext, useEffect, useState } from "react"
import { observer } from 'mobx-react-lite';
import { BbbContext } from '../logic/bbb';
import { Button, Col, Row, Space } from 'antd';

const AuthGuard = observer((props) => {
    const bbb = useContext(BbbContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        bbb.initialize()
    }, [])

    if (!bbb.isAuthenticated)
        return (
            <>
                <Row>
                    <Col span={8}></Col>
                    <Col>
                        <Space direction="vertical">
                            RadioMaitreya control de transmisión
                            <Button
                                onClick={(e) => {
                                    bbb.login();
                                    setLoading(true);
                                }}
                                disabled={loading}
                            >Iniciar sesión</Button>
                        </Space>
                    </Col>
                </Row>
            </>
        )

    return (
        <>{props.children}</>
    )
});

export default AuthGuard
