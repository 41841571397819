import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import { observer } from 'mobx-react-lite';

import { BbbContext } from '../logic/bbb';
import ConferencesList from '../components/ConferencesList';
import ConferencesTopLevelControl from '../components/ConferencesTopLevelControl';
import SystemControl from '../components/SystemControl';
import RadioRelayDashboard from '../components/RadioRelayDashboard';


const DashboardTab = observer(() => {
    const bbb = useContext(BbbContext);

    return <>
        <RadioRelayDashboard/>
        {/*<SystemControl/>*/}
        <ConferencesTopLevelControl/>

        {bbb.conferences && <ConferencesList conferences={bbb.conferences}/>}
    </>
})

export default DashboardTab

