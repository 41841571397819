import { flow, makeAutoObservable } from "mobx"
import { ChannelDto, RadioRelayClient, RadioRelayDashboard, StreamOutMode } from '../../gen/BBBApiService';
import { appConfig } from '../../config';
import { notifyError } from '../../utils/notifications';
import { httpClient } from '../../services/httpClient';

export class RelayStore {
    dashboard: RadioRelayDashboard | undefined;
    isLoading: boolean = false;

    private client: RadioRelayClient;

    constructor(client: RadioRelayClient) {
        makeAutoObservable(this)
        this.client = client
    }

    fetchDashboard = flow(function* (this: RelayStore) {
        this.isLoading = true
        try {
            this.dashboard = yield this.client.getDashboard();
        } catch (err) {
            notifyError(err)
        } finally {
            this.isLoading = false
        }
    })

    setStreamOutMode = flow(function* (this: RelayStore, channel: string, mode: StreamOutMode) {
        try {
            if (mode === StreamOutMode.Auto)
                yield this.client.setStreamOutModeAuto(new ChannelDto({channelId: channel}))
            else
                yield this.client.setStreamOutModeManual(new ChannelDto({channelId: channel}))
        } catch (err) {
            notifyError(err)
        }
    })

    startStreamOut = flow(function* (this: RelayStore, channel: string) {
        try {
            yield this.client.startStreamOutChannel(new ChannelDto({channelId: channel}))
        } catch (err) {
            notifyError(err)
        }
    })

    stopStreamOut = flow(function* (this: RelayStore, channel: string) {
        try {
            yield this.client.stopStreamOutChannel(new ChannelDto({channelId: channel}))
        } catch (err) {
            notifyError(err)
        }
    })

    // saveConfiguration = flow(function* (this: RelayStore, config: IPrivadoConfiguration) {
    //     this.isLoading = true
    //     try {
    //         yield this.client.saveConfiguration(new PrivadoConfiguration(config));
    //         notifySuccess("La configuración fue actualizada exitosamente")
    //         yield this.fetchConfiguration()
    //     } catch (err) {
    //         console.log(err)
    //         notifyError(err)
    //     } finally {
    //         this.isLoading = false
    //     }
    // })
}

const client = new RadioRelayClient(appConfig.baseUrl, httpClient)
const initializedStore = new RelayStore(client)

export function RelayStoreFactory() {
    return initializedStore;
}
