import { notification } from 'antd';
import { ProblemDetails } from '../gen/BBBApiService';

export function notifyError(err: string | Error | ProblemDetails) {
    let text = ""

    if (err instanceof Error)
        text = err.message;
    else if (err instanceof ProblemDetails) {
        // TODO: could retrieve actual errors
        text = err.title!;
    } else {
        text = err;
    }

    console.warn(err);
    notification.error({
        message: 'Error',
        description: text,
    });
}

export function notifySuccess(text: string) {
    notification.success({
        message: 'Éxito',
        description: text,
    });
}

