import React from 'react';
import 'antd/dist/antd.css';
import MainScreen from './containers/MainScreen';
import { BbbStoreFactory } from './logic/bbb/store';
import { BbbContext } from './logic/bbb';
import AuthGuard from './containers/AuthGuard';

const bbbStore = BbbStoreFactory()

function App() {
    return (
        <BbbContext.Provider value={bbbStore}>
            <AuthGuard>
                <MainScreen/>
            </AuthGuard>
        </BbbContext.Provider>
    );
}

export default App;
