import React from 'react'
import { Select, Tooltip } from 'antd';
import { appConfig } from '../config';

const {Option} = Select;
const options = [18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1, 0]

type Props = {
    value: number | undefined;
    onChange: any;
    loading: boolean;
    disabled: boolean;
}

export const EnergyLevelSelect: React.FC<Props> = ({value, onChange, loading, disabled}) => (
    <Tooltip title="Nivel de puerta de ruido (noise gate)">
        <Select size={appConfig.ui.buttonSize} value={value} onChange={onChange} loading={loading} disabled={disabled}
                dropdownMatchSelectWidth={false}>
            {value === undefined && <Option value="">?</Option>}
            {options.map(o => <Option key={o} value={o * 100}>{o * 100}</Option>)}
        </Select>
    </Tooltip>
);

export default EnergyLevelSelect
