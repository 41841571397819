import React, { useCallback, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ReactJson from 'react-json-view'
import { Button, Col, Divider, Input, Row, Space, Spin, Tooltip, Statistic, Typography } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import { BbbContext } from '../logic/bbb';
import { appConfig } from '../config';

const StatusTab = observer((props) => {
    const bbb = useContext(BbbContext);

    useEffect(() => {
        bbb.fetchStatus()
    }, [])

    const handleOnFetch = useCallback(() => {
        bbb.fetchStatus()
    }, [bbb])

    if (bbb.isStatusLoading)
        return <Spin/>

    return (<>
        <Row>
            <Col span={1}>
                <Tooltip title="Actualizar" placement="topLeft">
                    <Button onClick={handleOnFetch} shape="circle"><ReloadOutlined/></Button>
                </Tooltip>
            </Col>
        </Row>
        <Row>
            {bbb.status?.servers?.map(s => {
                    const {isModShoutLoaded, apiStatus} = s;
                    // @ts-ignore
                    const serverStatus = {isModShoutLoaded, system: apiStatus!.response!};
                    return <Col span={8} key={s.name}>
                        <Typography.Title level={5}>{s.name}</Typography.Title>
                        <ReactJson
                            src={serverStatus}
                            displayDataTypes={false}
                            displayObjectSize={false}
                            collapsed={false}
                        />
                    </Col>;
                }
            )}
        </Row>
    </>)
});

export default StatusTab
