import { flow, makeAutoObservable } from "mobx"
import {
    AppConfiguration, ConferenceMemberDto, ConferencesDto, ConfigurationClient, DashConference, DashMeta,
    FreeSwitchClient,
    FreeSwitchCommandRequestDto, FsCommandResult,
    FsServerStatus, IDashboard, MemberStatus, SetEnergyLevelRequest, SetMemberVolumeRequest, StreamInMode,
    StreamInTranslatorMode, StreamInTranslatorModeRequest, StreamInTranslatorPlayRequest,
    StreamInTranslatorVolumeRequest, StreamInVolumeRequest, StreamOutMode, TransmissionStatus
} from '../../gen/BBBApiService';
import { orderBy } from '../../utils/orderBy';
import { appConfig } from '../../config';
import { AuthService } from '../../services/authService';
import { User } from 'oidc-client';
import { notifyError, notifySuccess } from '../../utils/notifications';
import { authSvc, httpClient } from '../../services/httpClient';
import * as http from 'http';


export class BbbStore {
    isDashboardLoading = false;
    streamInLoading: string[] = [];
    streamOutLoading: string[] = [];
    recordingLoading: string[] = [];
    streamInTranslatorLoading: string[] = [];
    mixerStreamLoading: string[] = [];
    memberLoading: string[] = [];

    conferences: DashConference[] | undefined = []
    servers: FsServerStatus[] | undefined = []
    meta: DashMeta | undefined;

    configuration: AppConfiguration | undefined;
    isConfigurationLoading: boolean = false;
    status: TransmissionStatus | undefined;
    isStatusLoading = false;
    isCommandLoading = false;
    commandResult: FsCommandResult[] | undefined;

    private authSvc: AuthService;
    private fsClient: FreeSwitchClient;
    private cfgClient: ConfigurationClient;

    private user: User | undefined;
    private memberStatus: MemberStatus | undefined;

    get isAuthenticated() {
        return !!this.user
    }

    constructor(fsClient: FreeSwitchClient, cfgClient: ConfigurationClient, authService: AuthService) {
        makeAutoObservable(this)
        this.fsClient = fsClient
        this.cfgClient = cfgClient
        this.authSvc = authService;
    }

    initialize = flow(function* (this: BbbStore) {
        const user = yield this.authSvc.getUser();
        if (user)
            this.user = user;
    })

    login = flow(function* (this: BbbStore) {
        yield this.authSvc.login()
    })

    logout = flow(function* (this: BbbStore) {
        this.user = undefined;
        yield this.authSvc.logout()
    })

    fetchStatus = flow(function* (this: BbbStore) {
        this.isStatusLoading = true
        try {
            this.status = yield this.fsClient.getTransmissionStatus();
        } catch (err) {
            notifyError(err)
        } finally {
            this.isStatusLoading = false
        }
    })

    fetchConfiguration = flow(function* (this: BbbStore) {
        this.isConfigurationLoading = true
        try {
            this.configuration = yield this.cfgClient.readConfig();
        } catch (err) {
            notifyError(err)
        } finally {
            this.isConfigurationLoading = false
        }
    })

    saveConfiguration = flow(function* (this: BbbStore, config: AppConfiguration) {
        this.isConfigurationLoading = true
        try {
            yield this.cfgClient.writeConfig(config);
            notifySuccess("La configuración fue actualizada exitosamente")
        } catch (err) {
            console.log(err)
            notifyError(err)
        } finally {
            this.isConfigurationLoading = false
        }
    })

    stopServer = flow(function* (this: BbbStore) {
        try {
            yield this.cfgClient.stopApplication();
            notifySuccess("Stopped server. Waiting for restart...");
        } catch (err) {
            console.log(err)
            notifyError(err)
        } finally {
        }
    })

    executeCommand = flow(function* (this: BbbStore, command: string) {
        this.isCommandLoading = true
        this.commandResult = undefined;
        try {
            this.commandResult = yield this.fsClient.postCommand(new FreeSwitchCommandRequestDto({command}));
        } catch (err) {
            console.log(err)
            notifyError(err)
        } finally {
            this.isCommandLoading = false
        }
    })

    fetchDashboard = flow(function* (this: BbbStore, noCache: boolean = false) {
        this.isDashboardLoading = true
        try {
            const data: IDashboard = yield this.fsClient.getDashboard(noCache);
            this.conferences = orderBy(data.conferences!, ["id"], "asc");
            this.servers = data.servers?.sort(compareServerStatus);
            this.meta = data.meta;
        } catch (err) {
            notifyError(err)
        } finally {
            this.isDashboardLoading = false;
        }
    })

    fetchMemberStatus = flow(function* (this: BbbStore, conferenceId: string, memberUuid: string, name: string) {
        try {
            this.memberStatus = undefined
            this.memberStatus = yield this.fsClient.getMemberStatus(conferenceId, memberUuid);
            console.log(name, this.memberStatus)
        } catch (err) {
            notifyError(err)
        } finally {
        }
    })

    streamInStart = flow(function* (this: BbbStore, conferenceId: string | undefined) {
        if (!conferenceId) console.log("err") // Log err
        this.addStreamInLoading(conferenceId)
        try {
            const dto = this.getConferencesDto(conferenceId);
            yield this.fsClient.streamInStart(dto)
        } catch (err) {
            notifyError(err)
            console.error(err)
        } finally {
            this.removeStreamInLoading(conferenceId);
        }
    })

    streamInStop = flow(function* (this: BbbStore, conferenceId: string | undefined) {
        if (!conferenceId) console.log("err") // Log err
        this.addStreamInLoading(conferenceId)
        try {
            const dto = this.getConferencesDto(conferenceId);
            yield this.fsClient.streamInStop(dto)
        } catch (err) {
            notifyError(err)
            console.error(err)
        } finally {
            this.removeStreamInLoading(conferenceId);
        }
    })

    streamInTranslatorPlay = flow(function* (this: BbbStore, play: boolean, confId: string, members: string[]) {
        if (!confId) console.log("err") // Log err
        members.forEach(m => this.addMemberLoading(m));
        try {
            if (play)
                yield this.fsClient.streamInTranslatorStart(new StreamInTranslatorPlayRequest({confId, members}));
            else
                yield this.fsClient.streamInTranslatorStop(new StreamInTranslatorPlayRequest({confId, members}));
        } catch (err) {
            notifyError(err)
            console.error(err)
        } finally {
            members.forEach(m => this.removeMemberLoading(m));
        }
    })

    muteMembers = flow(function* (this: BbbStore, conferenceId: string) {
        // TODO: isLoading startStreamIn confid...
        if (!conferenceId) console.log("err") // Log err
        try {
            const dto = this.getConferencesDto(conferenceId);
            yield this.fsClient.muteAllMembers(dto)
        } catch (err) {
            notifyError(err)
        } finally {

        }
    })

    muteMember = flow(function* (this: BbbStore, conferenceId: string, memberId: string) {
        this.addMemberLoading(memberId)
        try {
            yield this.fsClient.muteMember(new ConferenceMemberDto({conferenceId, memberId}))
        } catch (err) {
            notifyError(err)
        } finally {
            this.removeMemberLoading(memberId)
        }
    })

    setEnergyLevel = flow(function* (this: BbbStore, conferenceId: string | "all", energy: number) {
        // set loading
        try {
            const ids = conferenceId === "all" ? this.conferences?.map(c => c.id!) : [conferenceId!];
            yield this.fsClient.setEnergyLevel(new SetEnergyLevelRequest({
                conferenceIds: ids,
                energy
            }))
        } catch (err) {
            notifyError(err)
        } finally {
            // this.removeStreamInLoading(conferenceId);
        }
    })

    setStreamInVolume = flow(function* (this: BbbStore, conferenceId: string | "all", volume: number) {
        this.addStreamInLoading(conferenceId)
        try {
            const ids = conferenceId === "all" ? this.conferences?.map(c => c.id!) : [conferenceId!];
            yield this.fsClient.streamInVolume(new StreamInVolumeRequest({
                conferenceIds: ids,
                vol: volume
            }))
        } catch (err) {
            notifyError(err)
        } finally {
            this.removeStreamInLoading(conferenceId);
        }
    })

    setStreamInTranslatorVolume = flow(function* (this: BbbStore, conferenceId: string | "all", volume: number) {
        this.addStreamInTranslatorLoading(conferenceId)
        try {
            const ids = conferenceId === "all" ? this.conferences?.map(c => c.id!) : [conferenceId!];
            yield this.fsClient.streamInTranslatorVolume(new StreamInTranslatorVolumeRequest({
                conferenceIds: ids,
                vol: volume
            }))
        } catch (err) {
            notifyError(err)
        } finally {
            this.removeStreamInTranslatorLoading(conferenceId);
        }
    })

    streamInTranslatorsConferenceStop = flow(function* (this: BbbStore, conferenceId: string | undefined | "all") {
        this.addStreamInTranslatorLoading(conferenceId!)
        try {
            const dto = this.getConferencesDto(conferenceId);
            console.log(dto)
            yield this.fsClient.streamInTranslatorConferenceStop(dto)
        } catch (err) {
            notifyError(err)
        } finally {
            this.removeStreamInTranslatorLoading(conferenceId!);
        }
    })

    setMemberVolume = flow(function* (this: BbbStore, conferenceId: string, memberId: string, volume: number) {
        this.addMemberLoading(memberId)
        try {
            yield this.fsClient.memberVolume(new SetMemberVolumeRequest({conferenceId, memberId, volume}))
        } catch (err) {
            notifyError(err)
        } finally {
            this.removeMemberLoading(memberId);
        }
    })

    setMemberVolumeOut = flow(function* (this: BbbStore, conferenceId: string, memberId: string, volume: number) {
        this.addMemberLoading(memberId)
        try {
            yield this.fsClient.memberVolumeOut(new SetMemberVolumeRequest({conferenceId, memberId, volume}))
        } catch (err) {
            notifyError(err)
        } finally {
            this.removeMemberLoading(memberId);
        }
    })

    streamOutStart = flow(function* (this: BbbStore, conferenceId: string | undefined) {
        if (!conferenceId) console.log("err") // Log err
        this.addStreamOutLoading(conferenceId)
        try {
            const dto = this.getConferencesDto(conferenceId);
            yield this.fsClient.streamOutStart(dto)
        } catch (err) {
            notifyError(err)
        } finally {
            this.removeStreamOutLoading(conferenceId);
        }
    })

    streamOutStop = flow(function* (this: BbbStore, conferenceId: string | undefined) {
        if (!conferenceId) console.log("err") // Log err
        this.addStreamOutLoading(conferenceId)
        try {
            const dto = this.getConferencesDto(conferenceId);
            yield this.fsClient.streamOutStop(dto)
        } catch (err) {
            notifyError(err)
        } finally {
            this.removeStreamOutLoading(conferenceId);
        }
    })

    mixerStreamStart = flow(function* (this: BbbStore, conferenceId: string | undefined | "all") {
        if (!conferenceId) console.log("err") // Log err
        this.addMixerStreamLoading(conferenceId!)
        try {
            const dto = this.getConferencesDto(conferenceId);
            yield this.fsClient.mixerStreamStart(dto)
        } catch (err) {
            notifyError(err)
        } finally {
            this.removeMixerStreamLoading(conferenceId!);
        }
    })

    mixerStreamStop = flow(function* (this: BbbStore, conferenceId: string | undefined | "all") {
        if (!conferenceId) console.log("err") // Log err
        this.addMixerStreamLoading(conferenceId!)
        try {
            const dto = this.getConferencesDto(conferenceId);
            yield this.fsClient.mixerStreamStop(dto)
        } catch (err) {
            notifyError(err)
        } finally {
            this.removeMixerStreamLoading(conferenceId!);
        }
    })

    streamInMode = flow(function* (this: BbbStore, conferenceId: string | undefined | "all", mode: StreamInMode.Auto | StreamInMode.Manual) {
        if (!conferenceId) console.log("err") // Log err
        this.addStreamInLoading(conferenceId)

        try {
            const dto = this.getConferencesDto(conferenceId);
            if (mode === StreamInMode.Auto) {
                yield this.fsClient.streamInModeAuto(dto)
            } else if (mode === StreamInMode.Manual) {
                yield this.fsClient.streamInModeManual(dto)
            }

        } catch (err) {
            notifyError(err)
        } finally {
            this.removeStreamInLoading(conferenceId);
        }
    })

    streamInTranslatorMode = flow(function* (this: BbbStore, conferenceId: string | undefined | "all", mode: StreamInTranslatorMode) {
        this.addStreamInTranslatorLoading(conferenceId!)

        try {
            const dto = this.getConferencesDto(conferenceId);
            yield this.fsClient.streamInTranslatorMode(new StreamInTranslatorModeRequest({
                conferenceIds: dto.ids,
                mode
            }))
        } catch (err) {
            notifyError(err)
        } finally {
            this.removeStreamInTranslatorLoading(conferenceId!)
        }
    })

    streamOutMode = flow(function* (this: BbbStore, conferenceId: string | undefined | "all", mode: StreamOutMode) {
        if (!conferenceId) console.log("err") // Log err
        this.addStreamOutLoading(conferenceId)

        try {
            const dto = this.getConferencesDto(conferenceId);
            if (mode === StreamOutMode.Auto) {
                yield this.fsClient.streamOutModeAuto(dto)
            } else if (mode === StreamOutMode.Manual) {
                yield this.fsClient.streamOutModeManual(dto)
            }
        } catch (err) {
            notifyError(err)
        } finally {
            this.removeStreamOutLoading(conferenceId);
        }
    })

    recordingStart = flow(function* (this: BbbStore, conferenceId: string) {
        this.addRecordingLoading(conferenceId)
        try {
            const dto = this.getConferencesDto(conferenceId);
            yield this.fsClient.recordingStart(dto)
        } catch (err) {
            notifyError(err)
        } finally {
            this.removeRecordingLoading(conferenceId);
        }
    })

    recordingStop = flow(function* (this: BbbStore, conferenceId: string) {
        this.addRecordingLoading(conferenceId)
        try {
            const dto = this.getConferencesDto(conferenceId);
            yield this.fsClient.recordingStop(dto)
        } catch (err) {
            notifyError(err)
        } finally {
            this.removeRecordingLoading(conferenceId);
        }
    })

    private getConferencesDto(conferenceId: string | undefined | "all") {
        const ids = conferenceId === "all" ? this.conferences?.map(c => c.id!) : [conferenceId!];
        return new ConferencesDto({ids});
    }

    private addStreamInLoading(conferenceId: string | undefined) {
        this.streamInLoading = [conferenceId!, ...this.streamInLoading]
    }

    private removeStreamInLoading(conferenceId: string | undefined) {
        this.streamInLoading = this.streamInLoading.filter(id => id !== conferenceId)
    }

    private addStreamOutLoading(conferenceId: string | undefined) {
        this.streamOutLoading = [conferenceId!, ...this.streamOutLoading]
    }

    private removeStreamOutLoading(conferenceId: string | undefined) {
        this.streamOutLoading = this.streamOutLoading.filter(id => id !== conferenceId)
    }

    private addRecordingLoading(conferenceId: string) {
        this.recordingLoading = [conferenceId!, ...this.recordingLoading]
    }

    private removeRecordingLoading(conferenceId: string) {
        this.recordingLoading = this.recordingLoading.filter(id => id !== conferenceId)
    }

    private addMemberLoading(memberId: string) {
        this.memberLoading = [memberId!, ...this.memberLoading]
    }

    private removeMemberLoading(memberId: string) {
        this.memberLoading = this.memberLoading.filter(id => id !== memberId)
    }

    private addStreamInTranslatorLoading(confId: string) {
        this.streamInTranslatorLoading = [confId!, ...this.streamInTranslatorLoading]
    }

    private removeStreamInTranslatorLoading(confId: string) {
        this.streamInTranslatorLoading = this.streamInTranslatorLoading.filter(id => id !== confId)
    }

    private addMixerStreamLoading(confId: string) {
        this.mixerStreamLoading = [confId!, ...this.streamInTranslatorLoading]
    }

    private removeMixerStreamLoading(confId: string) {
        this.mixerStreamLoading = this.streamInTranslatorLoading.filter(id => id !== confId)
    }

    isStreamInLoading(confId: string) {
        return this.streamInLoading.includes(confId) || this.streamInLoading.includes("all");
    }

    isMixerStreamLoading(confId: string) {
        return this.mixerStreamLoading.includes(confId) || this.mixerStreamLoading.includes("all");
    }

    isStreamInTranslatorLoading(confId: string) {
        return this.streamInTranslatorLoading.includes(confId) || this.streamInTranslatorLoading.includes("all");
    }

    isStreamOutLoading(confId: string) {
        return this.streamOutLoading.includes(confId) || this.streamOutLoading.includes("all");
    }

    isRecordingLoading(confId: string) {
        return this.recordingLoading.includes(confId) || this.recordingLoading.includes("all");
    }

    isMemberLoading(memberId: string) {
        return this.memberLoading.includes(memberId) || this.memberLoading.includes("all");
    }
}

function compareServerStatus(a: FsServerStatus, b: FsServerStatus) {
    if (a.name! < b.name!) {
        return -1;
    }
    if (a.name! > b.name!) {
        return 1;
    }
    return 0;
}


const freeSwitchClient = new FreeSwitchClient(appConfig.baseUrl, httpClient)
const configurationClient = new ConfigurationClient(appConfig.baseUrl, httpClient)

const initializedStore = new BbbStore(freeSwitchClient, configurationClient, authSvc)

export function BbbStoreFactory() {
    return initializedStore;
}
