import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { SwitchSize } from 'antd/lib/switch';

const {
    // REACT_APP_HOST: host,
    // REACT_APP_BASE_PATH: basePath,
    NODE_ENV,
    // PUBLIC_URL: publicUrl,
    // REACT_APP_SENTRY_DSN: sentryDsn,
} = process.env;

const tenantId = "ee78d6a1-8f64-4e3e-91e8-9c393912c0f9";

const isDev = NODE_ENV === "development";
const baseUrl = isDev ? "http://localhost:5000" : "https://transmision.radiomaitreya.org";

interface AppConfig {
    baseUrl: string,
    isDev: boolean,
    auth: any,
    ui: {
        switchSize: SwitchSize;
        buttonSize: SizeType
    }
}

export const appConfig: AppConfig = {
    baseUrl,
    isDev,
    auth: {
        clientId: isDev ? '81b2c3ab-4710-48cc-9a19-fe1cc1099c63' : "63492303-a841-4094-86f3-b8cd1db13565",
        authority: 'https://login.microsoftonline.com/' + tenantId + "/",
        clientRoot: baseUrl + "/",
        scope: "openid profile",
    },
    ui: {
        // buttonSize: "small",
        // switchSize: "small"
        buttonSize: "middle",
        switchSize: "default"
    }
}

console.log(appConfig)
