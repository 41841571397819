import { Log, User, UserManager, UserManagerSettings } from 'oidc-client';
import { appConfig } from '../config';

/**
 * Implicit flow auth service
 */
export class AuthService {
    public userManager: UserManager;

    constructor() {
        const settings : UserManagerSettings = {
            authority: appConfig.auth.authority,
            client_id: appConfig.auth.clientId,
            redirect_uri: appConfig.auth.clientRoot + "signin-callback.html",
            silent_redirect_uri: appConfig.auth.clientRoot + "silent-renew.html",
            post_logout_redirect_uri: appConfig.auth.clientRoot,
            response_type: 'code',
            scope: appConfig.auth.scope,
            automaticSilentRenew: true,
            includeIdTokenInSilentRenew: true,
            loadUserInfo: false,

        };
        this.userManager = new UserManager(settings);

        Log.logger = console;
        Log.level = Log.WARN;
    }

    public getUser(): Promise<User | null> {
        return this.userManager.getUser();
    }

    public login(): Promise<void> {
        return this.userManager.signinRedirect();
    }

    public renewToken(): Promise<User> {
        return this.userManager.signinSilent();
    }

    public logout(): Promise<void> {
        return this.userManager.removeUser();
        // return this.userManager.signoutRedirect();
    }
}
