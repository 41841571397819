import { flow, makeAutoObservable } from "mobx"
import { RecordingClient, RecordingDashboard } from '../../gen/BBBApiService';
import { appConfig } from '../../config';
import { notifyError } from '../../utils/notifications';
import { httpClient } from '../../services/httpClient';

export class RecordingStore {
    dashboard: RecordingDashboard | undefined;
    isLoading: boolean = false;

    private client: RecordingClient;

    constructor(client: RecordingClient) {
        makeAutoObservable(this)
        this.client = client
    }

    fetchDashboard = flow(function* (this: RecordingStore) {
        this.isLoading = true
        try {
            this.dashboard = yield this.client.dashboard();
        } catch (err) {
            notifyError(err)
        } finally {
            this.isLoading = false
        }
    })
}

const client = new RecordingClient(appConfig.baseUrl, httpClient)

const initializedStore = new RecordingStore(client)

export function RecordingStoreFactory() {
    return initializedStore;
}
