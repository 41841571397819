import React, { useCallback, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Col, Divider, Input, Row, Space, Spin, Typography } from 'antd';

import { BbbContext } from '../logic/bbb';
import TextArea from 'antd/es/input/TextArea';

const CommandTab = observer((props) => {
    const bbb = useContext(BbbContext);
    const [commandText, setCommandText] = useState("conference ");
    const [commandHistory, setCommandHistory] = useState<string[]>([]);

    const handleOnClick = useCallback(() => {
        bbb.executeCommand(commandText)
        setCommandHistory(p => {
            if (p.length > 20) {
                p.pop()
            }
            return [commandText, ...p]
        })
    }, [bbb, commandText])

    return (<>
            <Row>
                <Col span={12}>
                    <Typography.Title level={5}>Ejecutar un comando en BBB:</Typography.Title>
                    <Input defaultValue={commandText} onChange={c => setCommandText(c.target.value)} onKeyDown={k => {
                        if (k.key === "Enter") handleOnClick()
                    }}/>
                    <Button onClick={handleOnClick} danger type="primary">Ejecutar</Button>
                </Col>
                <Col span={12}>
                    <Typography.Title level={5}>Historial:</Typography.Title>
                    {commandHistory.map((c, i) => <React.Fragment key={i}>
                        <Typography.Text keyboard copyable>{c}</Typography.Text></React.Fragment>)}
                </Col>
            </Row>
            <Row>
            </Row>
            {bbb.isCommandLoading
                ? <Spin/>
                : <Row>
                    {bbb.commandResult?.map(r => {
                        const {serverName, output} = r;
                        // @ts-ignore
                        return <Col span={8} key={serverName}>
                            <Typography.Title level={5}>{serverName}</Typography.Title>
                            <TextArea value={output} readOnly autoSize style={{
                                whiteSpace: "pre",
                                overflowWrap: "normal",
                                overflowX: "scroll",
                                minHeight: 100
                            }}/>
                        </Col>;
                    })}
                </Row>}
            <Row>
                <pre style={{whiteSpace: "pre-wrap"}}>
                    conference 90001 list <br/>
                    conference 90001 play_status<br/>
                    play file_path [async|member_id [nomux]] <br/>
                    stop [current|all|async|last] [member_id]<br/>

                    norecord filename|all <br/>
                    record file <br/>
                    recording [start|stop|check|pause|resume] [filename|all] <br/>
                    chkrecord confname <br/>
                </pre>
            </Row>
        </>
    )
}
);

export default CommandTab
