import React, { useCallback, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { PrivadoContext } from '../logic/privado';
import { Button, Col, Divider, Input, Row, Space, Spin, Tooltip, Statistic } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

const {TextArea} = Input;

const PrivadoTab = observer((props) => {
    const privado = useContext(PrivadoContext);
    const [notification, setNotification] = useState("");

    const {isLoading} = privado
    const {config, connectionCount} = privado.dashboard || {}

    useEffect(() => {
        privado.fetchConfiguration()
    }, [])

    const handleOnFetch = useCallback(() => {
        privado.fetchConfiguration()
    }, [privado])

    const handleOnSave = useCallback(() => {
        const newConfig = {...config, notification}
        privado.saveConfiguration(newConfig)
    }, [privado, config, notification])

    if (isLoading)
        return <Spin/>

    return (<>
        <Row>
            <Col span={1}>
                <Tooltip title="Actualizar" placement="topLeft">
                    <Button onClick={handleOnFetch} shape="circle"><ReloadOutlined/></Button>
                </Tooltip>
            </Col>
            <Col span={8}>
                <Statistic title="Conectados en la página" value={connectionCount}/>
            </Col>
        </Row>
        <Row>
            Notificación:
            <TextArea defaultValue={config?.notification} rows={4} onChange={c => setNotification(c.target.value)}/>
        </Row>
        <Row>
            <Button type="primary" onClick={handleOnSave}>Guardar</Button>
        </Row>
    </>)
});

export default PrivadoTab
