import React, { useCallback, useContext, useEffect, useState } from "react"
import { observer } from 'mobx-react-lite';
import { BbbContext } from '../logic/bbb';
import { Button, Col, Input, Row, Space } from 'antd';
import { AppConfiguration } from '../gen/BBBApiService';

const ConfigurationTab = observer((props) => {
    const bbb = useContext(BbbContext);
    const [isError, setIsError] = useState(false);
    const [config, setConfig] = useState<AppConfiguration>();

    useEffect(() => {
        bbb.fetchConfiguration()
    }, [])

    // If you want a debounce
    // https://medium.com/@gabrielmickey28/using-debounce-with-react-components-f988c28f52c1
    const handleChange = useCallback(({target}) => {
        const {value} = target
        setIsError(false)
        try {
            const obj = JSON.parse(value)
            setConfig(obj);
        } catch (err) {
            setIsError(true)
        }
    }, [])

    const handleOnSave = useCallback(() => {
        bbb.saveConfiguration(config!)
    }, [bbb, config])

    const handleOnStop = useCallback(() => {
        const confirm = window.confirm("Está seguro que quiere reiniciar el servicio RMT?")
        if (confirm) bbb.stopServer()
    }, [bbb, config])

    const loading = bbb.isConfigurationLoading
    const disabled = isError || loading || !config
    const textColor = isError ? "red" : "black"

    return (
        <>
            <Row>
                <Col span={8}>
                    <Space direction="horizontal">
                        <Button type="primary" disabled={disabled} loading={loading}
                                onClick={handleOnSave}>Guardar</Button>
                        <Button type="primary" onClick={handleOnStop}>Reiniciar el servicio</Button>
                    </Space>
                </Col>
            </Row>
            <Row>
                {bbb.configuration &&
                <Input.TextArea
                    onChange={handleChange}
                    rows={35}
                    style={{
                        color: textColor,
                        fontFamily: "SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace",
                        fontSize: 12
                    }}
                    defaultValue={JSON.stringify(bbb.configuration, null, 2)}
                />}
            </Row>
        </>
    )


});

export default ConfigurationTab
