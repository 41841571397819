import React, { useCallback, useContext, useEffect, useState } from "react"
import { Button, Col, Divider, Row, Space, Spin, Switch, Tag, Tooltip, Typography } from 'antd';
import { BbbContext } from '../logic/bbb';
import { observer } from 'mobx-react-lite';
import { StreamInMode, StreamInTranslatorMode, StreamOutMode } from '../gen/BBBApiService';
import VolumeSelect from './VolumeSelect';
import { ReloadOutlined, RightOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ServerBadge from './ServerBadge';
import StreamInTranslatorModeSelect from './StreamInTranslatorModeSelect';
import { appConfig } from '../config';

const {Text} = Typography

const ConferencesTopLevelControl = observer(() => {
    const bbb = useContext(BbbContext);
    const [isAutoRefresh, setIsAutoRefresh] = useState(true);

    useEffect(() => {
        bbb.fetchDashboard()
    }, [])

    useEffect(() => {
        let fetchInterval;
        if (isAutoRefresh) {
            fetchInterval = setInterval(() => bbb.fetchDashboard(), 3 * 1000);
        } else {
            clearInterval(fetchInterval);
        }
        return () => {
            clearInterval(fetchInterval);
        };
    }, [isAutoRefresh])

    const forceFetchDashboard = useCallback(() => {
        bbb.fetchDashboard(true)
    }, [bbb]);

    const setStreamInModeAuto = useCallback(() => {
        bbb.streamInMode("all", StreamInMode.Auto);
    }, [bbb]);

    const setStreamInModeManual = useCallback(() => {
        bbb.streamInMode("all", StreamInMode.Manual);
    }, [bbb]);

    const setStreamInTranslatorMode = useCallback((mode: StreamInTranslatorMode) => {
        bbb.streamInTranslatorMode("all", mode)
    }, [bbb]);

    const setStreamOutModeAuto = useCallback(() => {
        bbb.streamOutMode("all", StreamOutMode.Auto);
    }, [bbb]);

    const setStreamOutModeManual = useCallback(() => {
        bbb.streamOutMode("all", StreamOutMode.Manual);
    }, [bbb]);

    const startStreamIn = useCallback(() => {
        bbb.streamInStart("all")
    }, [bbb]);

    const stopStreamIn = useCallback(() => {
        bbb.streamInStop("all")
    }, [bbb]);

    const setStreamInVolume = useCallback((vol: number) => {
        bbb.setStreamInVolume("all", vol)
    }, [bbb]);

    const muteMembers = useCallback(() => {
        bbb.muteMembers("all")
    }, [bbb]);

    const startStreamOut = useCallback(() => {
        bbb.streamOutStart("all")
    }, [bbb]);

    const stopStreamOut = useCallback(() => {
        bbb.streamOutStop("all")
    }, [bbb]);

    const stopMixerStream = useCallback(() => {
        bbb.mixerStreamStop("all")
    }, [bbb]);

    const startMixerStream = useCallback(() => {
        bbb.mixerStreamStart("all")
    }, [bbb]);

    const startRecording = useCallback(() => {
        bbb.recordingStart("all")
    }, [bbb]);

    const stopRecording = useCallback(() => {
        bbb.recordingStop("all")
    }, [bbb]);

    const isStreamInLoading = bbb.streamInLoading.length > 0
    const isStreamOutLoading = bbb.streamOutLoading.length > 0
    const isRecordingLoading = bbb.recordingLoading.length > 0
    const isMixerStreamLoading = bbb.mixerStreamLoading.length > 0

    return (
        <>
            <Row style={{backgroundColor: "#f5f5f5", borderRadius: 4, padding: 2}} justify="center">
                <Col span={4}>
                    {bbb.meta?.isTestMode &&
                    <Tag icon={<ExclamationCircleOutlined/>} color="warning" className="blink"> MODO PRUEBAS</Tag>}
                </Col>
                <Col span={20}>
                    {(!bbb.servers || bbb.servers.length === 0)
                        ? bbb.isDashboardLoading ? <Spin/> : "No hay servidores configurados"
                        : bbb.servers?.map(s => <ServerBadge key={s.id + s.serverType} server={s}/>)
                    }
                </Col>
            </Row>
            <Row style={{backgroundColor: "#f5f5f5", borderRadius: 4, padding: 4}} align="middle">
                <Col span={2}>
                    <Space>
                        <Tooltip title="Actualizar automaticamente" placement="topLeft">
                            <Switch loading={bbb.isDashboardLoading} defaultChecked={isAutoRefresh}
                                    onChange={(checked) => {
                                        setIsAutoRefresh(checked);
                                    }}/>
                        </Tooltip>
                        <Tooltip title="Actualizar (no cache)">
                            <Button size={appConfig.ui.buttonSize} onClick={forceFetchDashboard}
                                    disabled={bbb.isDashboardLoading} shape="circle"><ReloadOutlined/></Button>
                        </Tooltip>
                    </Space>
                </Col>
                <Col span={7}>
                    <Tooltip title="Todas salas: Español (fondo) en modo AUTO">
                        <Button size={appConfig.ui.buttonSize} onClick={setStreamInModeAuto} disabled={isStreamInLoading}>Auto</Button>
                    </Tooltip>
                    <Tooltip title="Todas salas: Español (fondo) en modo MANUAL">
                        <Button size={appConfig.ui.buttonSize} onClick={setStreamInModeManual} disabled={isStreamInLoading}>Man</Button>
                    </Tooltip>
                    {" "}
                    <Tooltip title="Todas salas: START Español (fondo)">
                        <Button size={appConfig.ui.buttonSize} type="primary" onClick={startStreamIn}
                                disabled={isStreamInLoading}>Start</Button>
                    </Tooltip>
                    <Tooltip title="Todas salas: STOP Español (fondo)">
                        <Button size={appConfig.ui.buttonSize} type="primary" danger onClick={stopStreamIn}
                                disabled={isStreamInLoading}>Stop</Button>
                    </Tooltip>
                    {" "}
                    <VolumeSelect value={0} onChange={setStreamInVolume} loading={false} disabled={isStreamInLoading}
                                  tooltip="Todas salas: Volumen Español (fondo)"/>
                </Col>
                <Col span={4}>
                    <StreamInTranslatorModeSelect
                        value={undefined} onChange={setStreamInTranslatorMode}
                        loading={false} disabled={isStreamInLoading}
                        tooltip="Todas salas: Modo de Español para los auriculares de traductores"/>
                </Col>
                <Col span={2}>
                    <Tooltip title="Todas salas: Mutear los traductores">
                        <Button size={appConfig.ui.buttonSize} danger type="primary" onClick={muteMembers}>Mute</Button>
                    </Tooltip>
                </Col>
                <Col span={4}>
                    <Tooltip title="Todas salas: Traducción modo AUTO">
                        <Button size={appConfig.ui.buttonSize} onClick={setStreamOutModeAuto} disabled={isStreamOutLoading}>Auto</Button>
                    </Tooltip>
                    <Tooltip title="Todas salas: Traducción modo MANUAL">
                        <Button size={appConfig.ui.buttonSize} onClick={setStreamOutModeManual} disabled={isStreamOutLoading}>Man</Button>
                    </Tooltip>
                    <Tooltip title="Todas salas: START Traducción">
                        <Button size={appConfig.ui.buttonSize} type="primary" onClick={startStreamOut}
                                disabled={isStreamOutLoading}>Start</Button>
                    </Tooltip>
                    <Tooltip title="Todas salas: STOP Traducción">
                        <Button size={appConfig.ui.buttonSize} type="primary" danger onClick={stopStreamOut}
                                disabled={isStreamOutLoading}>Stop</Button>
                    </Tooltip>
                </Col>
                <Col span={2}>
                    <Tooltip title="Todas salas: START Transmissión">
                        <Button size={appConfig.ui.buttonSize} type="primary" onClick={startMixerStream}
                                disabled={isMixerStreamLoading}>Start</Button>
                    </Tooltip>
                    <Tooltip title="Todas salas: STOP Transmissión">
                        <Button size={appConfig.ui.buttonSize} type="primary" danger onClick={stopMixerStream}
                                disabled={isMixerStreamLoading}>Stop</Button>
                    </Tooltip>
                </Col>
                {/*<Col span={2}>*/}
                {/*    <Tooltip title="Todas salas: START Grabación">*/}
                {/*        <Button size={appConfig.ui.buttonSize}type="primary" onClick={startRecording} disabled={isRecordingLoading}>Start</Button>*/}
                {/*    </Tooltip>*/}
                {/*    <Tooltip title="Todas salas: STOP Grabación">*/}
                {/*        <Button size={appConfig.ui.buttonSize}type="primary" danger onClick={stopRecording}*/}
                {/*                disabled={isRecordingLoading}>Stop</Button>*/}
                {/*    </Tooltip>*/}
                {/*</Col>*/}

            </Row>
            <Row style={{backgroundColor: "#fadb14", borderRadius: 4, padding: 4, marginTop: 3, marginBottom: 3}}>
                <Col span={4}><Text>Salas {bbb.conferences?.length}</Text></Col>
                <Col span={5}><Text strong>ESPAÑOL (fondo)</Text> <RightOutlined/></Col>
                <Col span={4}><Text strong>ESPAÑOL (auriculares trad.)</Text> <RightOutlined/></Col>
                <Col span={3}><Text strong>TRADUCTORES</Text> <RightOutlined/></Col>
                <Col span={4}><Text strong>TRADUCCIÓN</Text> <RightOutlined/></Col>
                <Col span={2}><Text strong>TRANSMISIÓN</Text> <RightOutlined/></Col>
                <Col span={2}><Text strong>MONITOREO</Text></Col>
            </Row>
        </>
    );
})

export default ConferencesTopLevelControl
