import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { appConfig } from './config';

console.log("Initializing Sentry")
Sentry.init({
    dsn: "https://bbc6fb38cf1b44f7bcd9cc6df10b030e@o242635.ingest.sentry.io/5474172",
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    enabled: !appConfig.isDev,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
