import { flow, makeAutoObservable } from "mobx"
import { IPrivadoConfiguration, PrivadoClient, PrivadoConfiguration, PrivadoDashboard } from '../../gen/BBBApiService';
import { appConfig } from '../../config';
import { notifyError, notifySuccess } from '../../utils/notifications';
import { httpClient } from '../../services/httpClient';

export class PrivadoStore {
    dashboard: PrivadoDashboard | undefined;
    isLoading: boolean = false;

    private client: PrivadoClient;

    constructor(client: PrivadoClient) {
        makeAutoObservable(this)
        this.client = client
    }

    fetchConfiguration = flow(function* (this: PrivadoStore) {
        this.isLoading = true
        try {
            this.dashboard = yield this.client.dashboard();
        } catch (err) {
            notifyError(err)
        } finally {
            this.isLoading = false
        }
    })

    saveConfiguration = flow(function* (this: PrivadoStore, config: IPrivadoConfiguration) {
        this.isLoading = true
        try {
            yield this.client.saveConfiguration(new PrivadoConfiguration(config));
            notifySuccess("La configuración fue actualizada exitosamente")
            yield this.fetchConfiguration()
        } catch (err) {
            console.log(err)
            notifyError(err)
        } finally {
            this.isLoading = false
        }
    })
}

const client = new PrivadoClient(appConfig.baseUrl, httpClient)

const initializedStore = new PrivadoStore(client)

export function PrivadoStoreFactory() {
    return initializedStore;
}
