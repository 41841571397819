import { Button, Image, Popover, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { SoundAnalysisClient } from '../gen/BBBApiService';
import { appConfig } from '../config';
import { httpClient } from '../services/httpClient';
import { AreaChartOutlined } from '@ant-design/icons';

type Props = {
    conferenceId: string;
}

const client = new SoundAnalysisClient(appConfig.baseUrl, httpClient)
const refreshIntervalMs = 2 * 1000;

const SpectrogramPopover: React.FC<Props> = ({conferenceId}: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [imgUrl, setImgUrl] = useState("");
    const [loading, setLoading] = useState(!imgUrl);

    const refresh = useCallback(() => {
            client.getSpectrogram(conferenceId)
                .then(response => {
                    if (response) {
                        const imageObjectURL = URL.createObjectURL(response.data);
                        URL.revokeObjectURL(imgUrl)
                        setImgUrl(imageObjectURL);
                        setLoading(false)
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },
        [imgUrl, conferenceId])

    useEffect(() => {
        const interval = setInterval(() => {
            if (isOpen) {
                refresh()
            }
        }, refreshIntervalMs);
        return () => clearInterval(interval);
    }, [isOpen, refresh]);

    const handleOpenChange = (newOpen: boolean) => {
        setIsOpen(newOpen);
        if (newOpen) {
            refresh()
        }
    };

    return (
        <Popover
            content={<div>
                <Spin tip="Cargando..." spinning={loading}>
                    <Image src={imgUrl} height={512} width={996} preview={false} placeholder={true}/>
                </Spin>
            </div>}
            title={`Espectrograma ${conferenceId}`}
            trigger="click"
            visible={isOpen}
            onVisibleChange={handleOpenChange}
        >
            <Button type="primary" shape="circle" icon={<AreaChartOutlined/>}></Button>
        </Popover>
    );
};

export default SpectrogramPopover;