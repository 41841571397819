import React, { useCallback, useContext } from 'react';
import { Button, Tabs } from 'antd';
import DashboardTab from './DashboardTab';
import { ControlOutlined, LogoutOutlined } from '@ant-design/icons';
import { BbbContext } from '../logic/bbb';
import ConfigurationTab from './ConfigurationTab';
import { PrivadoContext } from '../logic/privado';
import PrivadoTab from './PrivadoTab';
import { RecordingContext } from '../logic/recording';
import StatusTab from './StatusTab';
import AudioSpectrum from './AudioSpectrum';
import CommandsTab from './CommandsTab';
import { appConfig } from '../config';

const {TabPane} = Tabs;

const MainScreen = () => {
    const bbb = useContext(BbbContext);
    const privado = useContext(PrivadoContext)
    const recording = useContext(RecordingContext)

    const handleOnTabChange = useCallback((tab) => {
        if (tab === "1") {
            bbb.fetchDashboard();
        } else if (tab === "2") {
            bbb.fetchConfiguration();
        } else if (tab === "3") {
            privado.fetchConfiguration();
        } else if (tab === "4") {
            recording.fetchDashboard();
        } else if (tab === "status") {
            bbb.fetchStatus();
        }
    }, [bbb, privado, recording])

    const operations = {
        right: <><Button size={appConfig.ui.buttonSize} onClick={() => bbb.logout()}><LogoutOutlined/>Cerrar sesión</Button></>,
    };

    return (
        <div style={{marginLeft: 4, marginRight: 4}}>
            <Tabs defaultActiveKey="1" tabBarExtraContent={operations} onChange={handleOnTabChange}
                  tabBarStyle={{marginBottom: 2}}>
                <TabPane tab={<><ControlOutlined/> Controles</>} key="1">
                    <DashboardTab/>
                </TabPane>
                <TabPane tab="Configuración" key="2" destroyInactiveTabPane={true}>
                    <ConfigurationTab/>
                </TabPane>
                <TabPane tab="Estado" key="estado" destroyInactiveTabPane={true}>
                    <StatusTab/>
                </TabPane>
                <TabPane tab="Privado" key="3" destroyInactiveTabPane={true}>
                    <PrivadoTab/>
                </TabPane>
                {/*<TabPane tab="Grabaciones" key="4" destroyInactiveTabPane={true}>*/}
                {/*    <RecordingTab/>*/}
                {/*</TabPane>*/}
                <TabPane tab="Monitoreo" key="audiospectrum" destroyInactiveTabPane={true} forceRender={false}>
                    <AudioSpectrum/>
                </TabPane>
                <TabPane tab="Comandos" key="commands" destroyInactiveTabPane={true} forceRender={false}>
                    <CommandsTab/>
                </TabPane>
            </Tabs>
        </div>
    );
};

export default MainScreen
