import React from 'react';

import ConferenceRow from './ConferenceRow';
import { DashConference } from '../gen/BBBApiService';


const ConferencesList = ({conferences}: { conferences: DashConference[] }) => {

    return (
        <>
            {(!conferences || conferences.length === 0) && "No hay salas activas"}
            {conferences.map(c =>
                <ConferenceRow key={c.id} c={c}/>
            )}
        </>
    )
}

export default ConferencesList;
